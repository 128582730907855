<template>
  <b-card no-body>
    <b-card-body>
      <marketing-request-search-bar :filter-action="searchData" />
    </b-card-body>
    <template v-if="dataLoading">
      <loading />
    </template>
    <template v-else>
      <template v-if="dataList.length > 0">
        <b-table
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
        >
          <template #cell(marketing_request_subject)="data">
            <div v-if="data.item.rcode">
              <small class="text-warning">{{ data.item.rcode }}</small>
            </div>
            {{ data.item.title? data.item.title : '-' }}
            <div>
              <b-badge
                v-if=" data.item.marketing_request_subject"
                variant="light-primary"
                class="mr-1"
                style="margin-bottom: 1%"
              >
                <feather-icon icon="FolderIcon" /> {{ data.item.marketing_request_subject }}
              </b-badge>
              <div>
                <b-badge
                  v-if="data.item.start_date"
                  variant="light-success"
                >
                  {{ moment(data.item.start_date).format('DD.MM.YYYY') }}
                </b-badge>
                -
                <b-badge
                  v-if="data.item.finish_date"
                  variant="light-danger"
                >
                  {{ moment(data.item.finish_date).format('DD.MM.YYYY') }}
                </b-badge>
                <b-badge
                  v-if=" moment(data.item.finish_date).endOf('day').diff(moment().startOf('day'),'days') <= 10 && data.item.finish_date"
                  variant="danger"
                  class="ml-1"
                >
                  {{ moment(data.item.finish_date).endOf('day').diff(moment().startOf('day'),'days') }} Gün Kaldı.
                  <template v-if="moment(data.item.finish_date).endOf('day').diff(moment().startOf('day'),'days') === 0">
                    Son Gün
                  </template>
                  <template v-if="moment(data.item.finish_date).endOf('day').diff(moment().startOf('day'),'days') < 0">
                    Tarihi Geçti
                  </template>
                </b-badge>
              </div>
            </div></template>
          <template #cell(department)="data">
            {{ data.item.department }}
            <div v-if="data.item.status_code">
              <b-badge :variant="'light-'+data.item.status_code_variant">
                <span class="font-weight-bold">Öncelik: </span>{{ data.item.status_code }}
              </b-badge>
            </div>
          </template>
          <template #cell(marketing_request_status)="data">
            <div v-if="data.item.marketing_request_status">
              <b-badge :variant="data.item.marketing_request_status_variant">
                {{ data.item.marketing_request_status }}
              </b-badge>
            </div>
            <div class="d-flex alignt-items-center">
              <small class="text-secondary"><feather-icon icon="SaveIcon" /> {{ moment(data.item.created).format('DD.MM.YYYY HH:mm:ss') }}</small>
            </div>
            <div
              v-if="data.item.modified"
              class="d-flex alignt-items-center"
            >
              <small class="text-warning"><feather-icon icon="EditIcon" /> {{ moment(data.item.modified).format('DD.MM.YYYY HH:mm:ss') }}</small>
            </div>
            <div v-if="data.item.username">
              <small class="text-muted"><span class="font-weight-bold">Talep Sahibi:</span> {{ data.item.username }}</small>
            </div>
            <div v-if="data.item.request_user">
              <small class="text-muted"><span class="font-weight-bold">Talebi Oluşturan:</span> {{ data.item.request_user }}</small>
            </div>
          </template>
          <template #cell(control)="data">
            <div class="text-right">
              <b-button
                size="sm"
                variant="outline-primary"
                :to="'/marketing/request/view/'+data.item.id"
              >
                Görüntüle
              </b-button>
            </div>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCounts"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </template>
      <b-alert
        v-else
        show
        variant="info"
        class="m-2"
      >
        <div class="alert-body text-center">
          Gelen talep bulunmuyor. 👏
        </div>
      </b-alert>
    </template>
  </b-card>
</template>
<script>
import {
  BCard, BTable, BPagination, BButton, BCardFooter, BBadge, BCardBody, BAlert,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import MarketingRequestSearchBar from '@/views/Marketing/Requests/Components/SearchBar.vue'
import Loading from '@/layouts/components/Loading.vue'

const tableName = 'com_marketing_request'
export default {
  name: 'Index',
  components: {
    BAlert,
    Loading,
    MarketingRequestSearchBar,
    BCard,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    BBadge,
    BCardBody,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          key: 'marketing_request_subject',
          label: 'Talep Konusu',
        },
        {
          key: 'department',
          label: 'Departman',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'marketing_request_status',
          label: 'Durum',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.rcode AS rcode`,
          `${tableName}.title AS title`,
          `${tableName}.start_date AS start_date`,
          `${tableName}.finish_date AS finish_date`,
          `${tableName}.created AS created`,
          `${tableName}.modified AS modified`,
          'com_marketing_request_subject.title AS marketing_request_subject',
          'com_marketing_request_status.title AS marketing_request_status',
          'com_marketing_request_status.variant AS marketing_request_status_variant',
          'com_user.name AS username',
          'request_user.name AS request_user',
          'com_department.title AS department',
          'com_marketing_request_status_code.title AS status_code',
          'com_marketing_request_status_code.variant AS status_code_variant',
        ],
        where: {},
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['marketingRequest/dataList']
    },
    dataCounts() {
      return this.$store.getters['marketingRequest/dataCounts']
    },
    saveData() {
      return this.$store.getters['marketingRequest/dataSaveStatus']
    },
    filterData() {
      return this.$store.getters['marketingRequest/filterData']
    },
    dataLoading() {
      return this.$store.getters['marketingRequest/dataLoading']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 25
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
    if (this.userData.id_com_department !== '16' && this.userData.id_com_department !== '24') {
      this.$swal({
        icon: 'error',
        title: 'Uyarı',
        text: 'Bu alana giriş için yetkiniz bulunmuyor.',
        confirmButtonText: this.$store.state.app.removeResultClose,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
      this.$router.push('/')
    }
  },
  methods: {
    searchData() {
      const orLike = {}
      const where = {}
      if (this.filterData.keywords) {
        orLike['com_user.name'] = this.filterData.keywords
        orLike['request_user.name'] = this.filterData.keywords
        orLike['com_marketing_request.rcode'] = this.filterData.keywords
        orLike['com_marketing_request.title'] = this.filterData.keywords
        this.dataQuery.or_like = orLike
      } else {
        delete this.dataQuery.or_like
      }
      if (this.filterData.start_date) {
        where['com_marketing_request.start_date >='] = this.filterData.start_date
      }
      if (this.filterData.finish_date) {
        where['com_marketing_request.finish_date <='] = this.filterData.finish_date
      }
      if (this.filterData.id_com_marketing_request_status) {
        where['com_marketing_request.id_com_marketing_request_status'] = this.filterData.id_com_marketing_request_status
      }
      if (this.filterData.id_com_marketing_request_subject) {
        where['com_marketing_request.id_com_marketing_request_subject'] = this.filterData.id_com_marketing_request_subject
      }
      if (this.filterData.id_com_marketing_request_status_code) {
        where['com_marketing_request.id_com_marketing_request_status_code'] = this.filterData.id_com_marketing_request_status_code
      }
      if (this.filterData.id_com_department) {
        where['com_user.id_com_department'] = this.filterData.id_com_department
      }
      this.dataQuery.where = where
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('marketingRequest/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('marketingRequest/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
